@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        -webkit-tap-highlight-color: transparent;
    }
}

@font-face {
    font-family: "Callista";
    src: local("Callista"),
    url("./fonts/Callista.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Varela";
    src: local("Varela"),
    url("./fonts/VarelaRound-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

html {
    background: rgb(54, 49, 61);
    font-family: "Varela", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    overflow-x: hidden;
}

.callista {
    font-family: "Callista", sans-serif;
}